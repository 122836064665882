import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import { Container } from "react-bootstrap"
import { color } from "../assets/styles/variables"
import Seo from "../components/Seo/Seo"



// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Container className="d-flex flex-column align-items-center py-5">
      <Seo title="Błąd 404 - Strony nie znaleziono"/>
      <h1>Błąd 404 - Strony nie znaleziono</h1>
      <p >
        Przepraszamy{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        nie znaleźliśmy strony której szukasz.
        <Link to="/" style={{color: color.secondary}}> Wróć na stronę główną</Link>
      </p>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
